import { defineStore } from 'pinia'

export const useDialogStore = defineStore(
'useDialogStore',{
  state: () => ({
        isShowQrCode: false,
        isShowEditUserProfile: true,
        roomUrl: ""
    }),
  actions: {
    showQrCode(){
        const battle = useRoomStore();
        const room_id = battle.room;
        if(room_id){
          this.roomUrl = `${location.origin}/room?id=${room_id}`;
        }
        this.isShowQrCode = true
    },
    hideQrCode(){
        this.isShowQrCode = false
    },
    showEditUserProfile(){
        this.isShowEditUserProfile = true
    },
    hideEditUserProfile(){
        this.isShowEditUserProfile = false
    }
  },
  persist: {
    storage: persistedState.localStorage,
    enabled: true
  },
})